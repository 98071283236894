"use client"

import React, { useState, useEffect } from 'react'
import { FaGithub } from "react-icons/fa";

export default function GrayDateCountdown({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  function calculateTimeLeft() {
    const difference = +targetDate - +new Date()
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const formatNumber = (num) => num.toString().padStart(2, '0');
  const timerComponents = [
    { label: 'days', value: timeLeft.days },
    { label: 'hours', value: timeLeft.hours },
    { label: 'minutes', value: timeLeft.minutes },
    { label: 'seconds', value: timeLeft.seconds }
  ].map(({ label, value }, index) => (
    <React.Fragment key={label}>
      <span className="mx-1">
        <span className="font-bold">{formatNumber(value || 0)}</span>
      </span>
      {index < 3 && <span className="mx-1 text-gray-700">:</span>}
    </React.Fragment>
  ));

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 mt-6">
      <h1 className="text-2xl font-bold text-gray-700 mb-8">Coming Soon</h1>
      <div className="text-3xl text-gray-700 text-center font-mono">
        {Object.keys(timeLeft).length ? timerComponents : <span>Time's up!</span>}
      </div>
      <div className="flex mt-8  text-gray-700 items-center">
        <a href="https://github.com/ATHman3" className="flex items-center text-gray-700  hover:text-blue-900 transition-colors duration-200">
          <FaGithub size={25} />
          <span className="ml-2">Visit my GitHub</span>
        </a>
      </div>
    </div>
  )
}
