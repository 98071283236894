import * as THREE from 'three'
import React, { Suspense, useRef, memo, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, Environment, useGLTF, ContactShadows, OrbitControls } from '@react-three/drei'
import HeroPage from './HeroPage'

// Preload model to improve performance
useGLTF.preload('/mac-draco.glb')

// Memoized Model component with optimized animations
const Model = memo(function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/mac-draco.glb', true)

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    const rotationFactor = 0.1
    const positionFactor = 0.1

    // Constrained floating animation
    group.current.rotation.x = THREE.MathUtils.lerp(
      group.current.rotation.x, 
      Math.min(Math.max(Math.cos(t / 2) / 20 + 0.25, -0.3), 0.3), 
      rotationFactor
    )
    group.current.rotation.y = THREE.MathUtils.lerp(
      group.current.rotation.y, 
      Math.sin(t / 4) / 20, 
      rotationFactor
    )
    group.current.rotation.z = THREE.MathUtils.lerp(
      group.current.rotation.z, 
      Math.sin(t / 8) / 20, 
      rotationFactor
    )
    group.current.position.y = THREE.MathUtils.lerp(
      group.current.position.y, 
      (-2 + Math.sin(t / 2)) / 2, 
      positionFactor
    )
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation-x={-0.425} position={[0, -0.04, 0.41]}>
        <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
          <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
          <mesh geometry={nodes['Cube008_2'].geometry}>
            <Html 
              className="content" 
              rotation-x={-Math.PI / 2} 
              position={[0, 0.05, -0.09]} 
              transform 
              occlude
            >
              <div onPointerDown={(e) => e.stopPropagation()}>
                <HeroPage />
              </div>
            </Html>
          </mesh>
        </group>
      </group>
      <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
      <group position={[0, -0.1, 3.39]}>
        <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
        <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
      </group>
      <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
    </group>
  )
})

// Custom hook for responsive scaling
function useResponsiveScale(defaultScale = 0.8, breakpoint = 768) {
  const [scale, setScale] = useState(defaultScale)

  useEffect(() => {
    const handleResize = () => {
      setScale(window.innerWidth <= breakpoint ? 0.6 : defaultScale)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [defaultScale, breakpoint])

  return scale
}

export default function MacBookScene() {
  const scale = useResponsiveScale()

  return (
    <Canvas 
      performance={{ min: 0.5 }}
      camera={{ position: [-5, 0, -15], fov: 55 }}
    >
      <pointLight position={[10, 10, 10]} intensity={1.5} />
      <Suspense fallback={null}>
        <group rotation={[0, Math.PI, 0]} position={[0, 1, 0]} scale={scale}>
          <Model />
        </group>
        <Environment preset="city" />
      </Suspense>
      <ContactShadows position={[0, -4.5, 0]} scale={20} blur={2} far={4.5} />
      <OrbitControls 
        enablePan={false} 
        enableZoom={false} 
        minPolarAngle={Math.PI / 2.2} 
        maxPolarAngle={Math.PI / 2.2} 
      />
    </Canvas>
  )
}