/* This example requires Tailwind CSS v2.0+ */
import GrayDateCountdown from './components/GrayDateCountdown'
export default function Example() {

  const targetDate = new Date(2024, 11, 24, 5, 14, 2)
  
  return (
    
      <div >
        <GrayDateCountdown targetDate={targetDate} />
      </div>
    
  )
}
